import React from 'react';
import { FaHeart } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import pixLogo from '../icons/pix-logo.svg'
import payPalLogo from '../icons/paypal-logo.svg'
import DonateButton from './DonateButton';

const Footer = () => {

  const { t } = useTranslation();

  return (
    <footer className="text-white py-8 px-4">
      <div className="w-full mx-auto">
        <div className="flex flex-col items-center justify-between">
          <div className="text-center  mb-6">
            <h2 className="text-2xl font-bold mb-2">{t('ad-free')}</h2>
            <p className="text-lg">{t('help-us-keep-this-site-independent-and-ad-free')}</p>
          </div>
          <div className="flex flex-row items-center">
            <DonateButton logo={payPalLogo} link="https://www.paypal.com/donate/?hosted_button_id=QD2PR7GMDQLAW" />
            <DonateButton logo={pixLogo} link="https://www.gerarpix.com.br/pix?code=ZZOQHbYh8E8iTYYkp0JRSO0WmRr6LW93SdhFZfSV02ataGMxeC-vT1DK6-y-VI1vet_YCREdjkCCU0qQpCPKMoW7SwgRevpRub7OJk6xtcNmoTJVwQFFj4a6i2ZCgq2-NuIQ4OxHqnVadVM01jZZ0cWUxFyfm7b4pO1zRphqw4s0ocX64hG9p8Vy-uuMFVGNXMQSufhF9NbvZiOQ-27kQpGGDOzKC9nN0i3fZQd98AcIfmQ-LFqYhhk36XWBWvX86fjASIvazd0" />
          </div>
        </div>
        <div className="text-center">
          <p className="text-sm italic mt-4">{t('every-contribution-makes-all-the-difference')}</p>
        </div>

        <div className="mt-5 pt-5 border-t border-green-300 flex flex-col justify-between items-center">

          <div className="flex flex-row items-center">

            <span className="mr-2 text-sm">{t('dedicated-to-all-soccer-lovers')}</span>
            <FaHeart className="text-red-500 animate-pulse" />
          </div>
          <p className="text-xs mt-4">&copy; 2024 peladeiro.app. Todos os direitos reservados.</p>
        </div>
      </div>

    </footer>
  );
};

export default Footer;