import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const InstallButton = () => {
  const { t } = useTranslation();
  const [installPrompt, setInstallPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();

      setInstallPrompt(e);

      setShowInstallButton(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () =>
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
  }, []);

  const handleInstallClick = () => {
    if (installPrompt) {
      installPrompt.prompt();
      installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          setInstallPrompt(null);
          setShowInstallButton(false);
        }
      });
    }
  };

  return (
    <>
      {showInstallButton && (
        <button
          className="bg-white text-black p-2 rounded h-auto text-sm mt-5"
          onClick={handleInstallClick}
        >
          {t("install-app")}
        </button>
      )}
    </>
  );
};

export default InstallButton;
