import { AnimatePresence } from "framer-motion";
import { FaShareAlt } from "react-icons/fa";
import PlayerPhoto from "./PhotoPlayer";
import PlayerLevel from "./PlayerLevel";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const ResultDraw = ({ teams, onShare, reference }) => {

  const { t } = useTranslation();

  return (
    teams && teams.length > 0 && (
      <div className="mb-8" ref={reference}>
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white text-center">
          {t('draw-result')}
          <button
            onClick={onShare}
            className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-300 w-full"
          >
            <FaShareAlt className="inline-block mr-2" /> {t('share-results')}
          </button>
        </h2>

        <div className="grid grid-cols-1 gap-4">
          <AnimatePresence>
            {teams.map((team, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded">
                <h3 className="text-lg font-semibold mb-2">{t('team')} {index + 1}</h3>

                <ul className="divide-y divide-gray-400">
                  {team.map((player, playerIndex) => (
                    <motion.li
                      key={player.id}
                      initial={{ opacity: 0, y: 50, scale: 0.5 }}
                      animate={{ opacity: 1, y: 0, scale: 1 }}
                      exit={{ opacity: 0, y: -50, scale: 0.8 }}
                      transition={{ delay: playerIndex * 1 }}
                      className="flex flex-row items-center justify-between p-3"
                    >
                      <div className="flex items-center">
                        <PlayerPhoto photo={player.photo} size={40} />
                        <span className="font-semibold ml-3">
                          {player.name}
                        </span>
                      </div>
                      <div className="flex items-center text-2xl">
                        <PlayerLevel level={player.level} />
                      </div>
                    </motion.li>
                  ))}
                </ul>
              </div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    )
  );
};

export default ResultDraw;
