const { FaStar } = require("react-icons/fa");

const PlayerLevel = (props) => {
  return (
    <div className="flex flex-wrap items-center">
      {Array.from({ length: 5 }, (_, index) => (
        <FaStar
          key={index}
          className={`${index < props.level ? "text-yellow-400" : "text-gray-300"
            } text-1xl  cursor-pointer `}
          onClick={() => props.onClick && props.onClick(index + 1)}
        />
      ))}
    </div>
  );
};

export default PlayerLevel;
