import { Analytics } from "@vercel/analytics/react";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./Home";
import './lib/i18n';

function App() {
  return (
    <div className="bg-gradient-to-r from-green-600 to-green-800 min-h-screen">
      <Header />
      <Home />

      <Analytics />
    </div>
  );
}

export default App;
