import { useTranslation } from "react-i18next";

const ConfirmDialog = (props) => {

  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h3 className="text-xl font-semibold mb-4">{props.title}</h3>
        <p className="mb-4">{props.text}</p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={props.onCancel}
            className="bg-gray-300 text-black p-2 rounded hover:bg-gray-400 transition duration-300"
          >
            {t('cancel')}
          </button>
          <button
            onClick={props.onConfirm}
            className="bg-red-500 text-white p-2 rounded hover:bg-red-600 transition duration-300"
          >
            {props.confirmButtonName}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
