import { React } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const DonateButton = ({ logo, link }) => {

    const { t } = useTranslation();

    const handleClick = () => {
        toast.info(t('you-will-be-redirected-thank-you'), {
            position: "bottom-center"
        });

        setTimeout(() => {
            window.open(link, '_blank')
        }, 2000);
    };
    return (
        <button
            className="bg-white text-purple-600 font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform mr-4 flex items-center"
            aria-label="donation button"
            onClick={handleClick}
        >
            <img src={logo} className='h-10' />
        </button>
    )
}

export default DonateButton;