import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaFutbol } from "react-icons/fa";
import InstallButton from "./InstallButton";
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [isHovered, setIsHovered] = useState(false);

  const { t } = useTranslation();



  return (
    <motion.div
      className="flex items-center justify-center p-6 cursor-pointer"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      aria-label="Peladeiro.app Logo"
      tabIndex={0}
    >
      <div className="relative">
        <motion.div
          className="absolute inset-0 bg-white opacity-20 rounded-full"
          animate={{
            scale: isHovered ? [1, 1.2, 1] : 1,
          }}
          transition={{ duration: 1, repeat: Infinity }}
        />
        <motion.div
          className="relative z-10 flex items-center justify-center bg-white rounded-full p-3 shadow-lg"
          animate={{
            rotate: isHovered ? 360 : 0,
          }}
          transition={{ duration: 2, ease: "linear", repeat: Infinity }}
        >
          <FaFutbol className="text-5xl text-green-700" />
        </motion.div>
      </div>
      <motion.div className="ml-4 flex flex-col items-start">
        <motion.h1
          className="text-4xl font-extrabold text-white tracking-tight"
          animate={{
            y: isHovered ? -5 : 0,
          }}
        >
          Peladeiro<span className="text-yellow-400">.app</span>
        </motion.h1>
        <motion.div
          className="flex flex-col items-center mt-2"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <span className="text-sm text-white font-semibold">
            {t('slogan')}
          </span>


          <InstallButton />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Header;
