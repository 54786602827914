import { FaUser } from "react-icons/fa";

const PlayerPhoto = (props) => {
  if (props.photo) {
    return (
      <img
        src={props.photo}
        alt="Player"
        className="object-cover rounded-full"
        style={{ width: props.size, height: props.size }}
      />
    );
  }
  return (
    <FaUser
      className="object-cover text-gray-400 bg-gray-200 rounded-full"
      style={{ width: props.size, height: props.size }}
    />
  );
};

export default PlayerPhoto;
