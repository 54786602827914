import { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const UploadPlayerList = (props) => {

  const { t } = useTranslation();
  const [text, setText] = useState(null);

  const handleUpload = () => {
    const regexStart = /^[\d\W]+/gm;
    const regexEnd = /[\W]+$/gm;

    const items = [
      ...new Set(
        text
          .replace(regexStart, "")
          .replace(regexEnd, "")
          .split("\n")
          .filter((item) => item.trim() !== "")
          .map((t) => t.trim())
      ),
    ];

    const players = items.map((t, index) => ({
      id: uuidv4(),
      name: t.length > 10 ? t.slice(0, 10) : t,
      arrivalOrder: index + 1,
      level: 3,
      photo: null,
    }));

    props.onUpload(players);
    props.onCancel();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h3 className="text-xl font-semibold mb-4">
          {t('import-player-list')}
        </h3>

        <textarea
          value={text}
          className="w-full p-2 border rounded mb-4 h-72"
          placeholder="1. Neymar Jr&#10;2. Cristiano&#10;3. Messi"
          onChange={(e) => setText(e.target.value)}
        ></textarea>
        <div className="flex justify-end space-x-2">
          <button
            onClick={props.onCancel}
            className="bg-gray-300 text-black p-2 rounded hover:bg-gray-400 transition duration-300"
          >
            {t('cancel')}
          </button>
          <button
            onClick={handleUpload}
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-300"
          >
            {t('import')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadPlayerList;
