import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { FaUserPlus, FaTrash, FaBars } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2canvas from "html2canvas";
import PlayerPhoto from "./components/PhotoPlayer";
import PlayerLevel from "./components/PlayerLevel";
import ConfirmDialog from "./components/ConfirmDialog";
import UploadPlayerList from "./components/UploadPlayerList";
import EditPlayer from "./components/EditPlayer";
import DrawParameters from "./components/DrawParameters";
import useBalanceTeams from "./hooks/useBalanceTeams";
import ResultDraw from "./components/ResultDraw";
import { useTranslation } from "react-i18next";
import Footer from "./components/Footer";

const Home = () => {
  const { t } = useTranslation();
  const { balanceTeams } = useBalanceTeams();


  const [players, setPlayers] = useState([]);
  const [newPlayer, setNewPlayer] = useState({
    name: "",
    level: 3,
    photo: null,
  });
  const [editPlayer, setEditPlayer] = useState(null);
  const [showUploadPlayer, setShowUploadPlayer] = useState(null);
  const [playersPerTeam, setPlayersPerTeam] = useState(1);
  const [teams, setTeams] = useState([]);
  const teamsRef = useRef(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [deleteAllConfirmation, setAllDeleteConfirmation] = useState(false);

  useLayoutEffect(() => {
    const localStoragePlayers = localStorage.getItem("players");

    if (localStoragePlayers) {
      setPlayers(JSON.parse(localStoragePlayers));
    } else {
      setPlayers([
        {
          id: "57f55976-5f11-4269-b2be-e4efbfc2c458",
          name: "Neymar Jr",
          level: 5,
          arrivalOrder: 1,
          photo: "./neymar.png",
        },
        {
          id: "a46876ed-d4c8-443b-a80f-565b7fbd4b1f",
          name: "Cristiano",
          level: 5,
          arrivalOrder: 2,
          photo: "./cr7.png",
        },
        {
          id: "9270f7bb-1c16-4100-a114-0e95f6841ff8",
          name: "Messi",
          level: 5,
          arrivalOrder: 3,
          photo: "./messi.png",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (teams && teams.length > 0 && teamsRef.current) {
      teamsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [teams]);

  useEffect(() => {
    localStorage.setItem("players", JSON.stringify(players));
  }, [players]);

  useEffect(() => {
    const maxPlayersPerTeam = Math.floor(players.length / 2);
    setPlayersPerTeam(Math.min(playersPerTeam, maxPlayersPerTeam));
  }, [playersPerTeam]);

  const handleAddPlayer = () => {
    newPlayer.name = newPlayer.name.trim();

    if (newPlayer.name === "") {
      toast.warning(t("player-name-is-required"));
      return;
    }

    if (
      players.find((x) => x.name.toLowerCase() === newPlayer.name.toLowerCase())
    ) {
      toast.error(t("player-already-registered"));
      return;
    }

    setPlayers([
      ...players,
      {
        ...newPlayer,
        id: uuidv4(),
        arrivalOrder: players.length + 1,
      },
    ]);

    setNewPlayer({ name: "", level: 3, photo: null });
    toast.success(t("player-added-successfully"));
  };

  const handleEditPlayer = (player) => {
    setEditPlayer(player);
  };

  const handleUploadPlayer = () => {
    setShowUploadPlayer(true);
  };

  const handleUpdatePlayer = (player) => {
    setPlayers(players.map((p) => (p.id === player.id ? player : p)));
    setEditPlayer(null);
    toast.success(t("player-updated-successfully"));
  };

  const handleDeletePlayer = (id) => {
    setDeleteConfirmation(id);
  };

  const handleDeleteAllPlayer = () => {
    setAllDeleteConfirmation(true);
  };

  const confirmDeletePlayer = () => {
    const items = players.filter((p) => p.id !== deleteConfirmation);
    reorder(items);
    setDeleteConfirmation(null);
    toast.success(t("player-deleted-successfully"));
  };

  const confirmDeleteAllPlayer = () => {
    setAllDeleteConfirmation(false);
    setPlayers([]);
    localStorage.clear();
    toast.success(t("players-successfully-deleted"));
  };

  const cancelDeletePlayer = () => {
    setDeleteConfirmation(null);
  };

  const cancelDeleteAllPlayer = () => {
    setAllDeleteConfirmation(false);
  };

  const onUpDraw = () => {
    setPlayersPerTeam(Math.max(Math.min(playersPerTeam + 1, players.length), 1));
  };

  const onDownDraw = () => {
    setPlayersPerTeam(Math.max(playersPerTeam - 1, 1));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(players);

    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    reorder(items);
  };

  const reorder = (items) => {
    const updatedItems = items.map((item, index) => ({
      ...item,
      arrivalOrder: index + 1,
    }));
    setPlayers(updatedItems);
  };

  const handleDrawTeams = () => {

    console.log(players, playersPerTeam)
    const newTeams = balanceTeams(players, playersPerTeam);

    setTeams(newTeams);
  };

  const handleShareResults = async () => {
    if (!teamsRef.current) return;

    try {
      const canvas = await html2canvas(teamsRef.current);
      const image = canvas.toDataURL("image/png");

      if (navigator.share) {
        await navigator.share({
          title: "Resultado do peladeiro.app",
          text: "Confira o sorteio dos times realizado no peladeiro.app",
          files: [
            new File([await (await fetch(image)).blob()], "peladeiro_app.png", {
              type: "image/png",
            }),
          ],
        });
        toast.success(t("image-generated-successfully"));
      } else {
        const link = document.createElement("a");
        link.href = image;
        link.download = "peladeiro_app.png";
        link.click();
        toast.success(t("image-downloaded-successfully"));
      }
    } catch (error) {
      toast.error(t("error-sharing-draw-please-try-again"));
    }
  };

  return (
    <div className="container mx-auto p-4 max-w-md">
      <ToastContainer limit={1} autoClose={1000} />

      <div className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          {t('player-registration')}
        </h2>
        <div className="flex flex-col space-y-4 mb-4">
          <input
            type="text"
            value={newPlayer.name}
            autoComplete="false"
            onChange={(e) =>
              setNewPlayer({ ...newPlayer, name: e.target.value })
            }
            placeholder={t('player-name')}
            maxLength="10"
            className="flex-grow p-2 border rounded"
          />
          <div className="flex items-center space-x-2">
            <span className="text-white">{t('level')}:</span>
            <div className="flex items-center text-2xl">
              <PlayerLevel
                level={newPlayer.level}
                onClick={(level) => {
                  setNewPlayer({ ...newPlayer, level });
                }}
              />
            </div>
          </div>

          <button
            onClick={handleAddPlayer}
            className="bg-white text-black p-2 rounded hover:bg-slate-50 transition duration-300 w-full md:w-auto h-20 text-lg"
          >
            <FaUserPlus className="inline-block mr-2" size={30} /> {t('add-player')}
          </button>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4 text-white">
          {t('player-list')} ({players.length})
          {players.length > 0 && (
            <button
              onClick={handleDeleteAllPlayer}
              className="bg-white text-black p-2 rounded h-auto text-sm ml-2"
            >
              {t('clean')}
            </button>
          )}
          {players.length === 0 && (
            <button
              onClick={handleUploadPlayer}
              className="bg-white text-black p-2 rounded h-auto text-sm ml-2"
            >
              {t('import-list')}
            </button>
          )}
        </h2>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="players">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-2"
              >
                {players.map((player, index) => (
                  <Draggable
                    key={player.id}
                    draggableId={player.id}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="flex flex-row items-center justify-between bg-gray-100 p-3 rounded"
                      >
                        <div
                          className="flex items-center"
                          onClick={() => handleEditPlayer(player)}
                        >
                          <div
                            {...provided.dragHandleProps}
                            className="mr-2 cursor-move"
                          >
                            <FaBars className="text-gray-500" />
                          </div>
                          <span className="text-sm mr-3">
                            #{player.arrivalOrder}
                          </span>
                          <PlayerPhoto photo={player.photo} size={40} />
                          <span className="font-semibold ml-3">
                            {player.name}
                          </span>
                        </div>
                        <div className="flex items-center">
                          <div onClick={() => handleEditPlayer(player)}>
                            <PlayerLevel level={player.level} />
                          </div>
                          <button
                            onClick={() => handleDeletePlayer(player.id)}
                            className="text-red-500 hover:text-red-700 pr-2 pl-2"
                          >
                            <FaTrash />
                          </button>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {deleteConfirmation && (
        <ConfirmDialog
          title={t('confirm-deletion')}
          text={t('are-you-sure-you-want-to-delete-this-player')}
          onCancel={cancelDeletePlayer}
          onConfirm={confirmDeletePlayer}
          confirmButtonName={t('delete')}
        />
      )}

      {deleteAllConfirmation && (
        <ConfirmDialog
          title={t('confirm-deletion')}
          text={t('are-you-sure-you-want-to-delete-all-players')}
          onCancel={cancelDeleteAllPlayer}
          onConfirm={confirmDeleteAllPlayer}
          confirmButtonName={t('delete')}
        />
      )}

      {editPlayer && (
        <EditPlayer
          value={editPlayer}
          onEdit={handleUpdatePlayer}
          onCancel={() => setEditPlayer(null)}
        />
      )}

      {showUploadPlayer && (
        <UploadPlayerList
          onCancel={() => setShowUploadPlayer(false)}
          onUpload={(players) => setPlayers(players)}
        />
      )}

      <DrawParameters
        onDraw={handleDrawTeams}
        onUp={onUpDraw}
        onDown={onDownDraw}
        playersPerTeam={playersPerTeam}
        playersLength={players.length}
      />

      <ResultDraw teams={teams} reference={teamsRef} onShare={handleShareResults} />
      <Footer />
    </div>
  );
};

export default Home;

