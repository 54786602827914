import { useTranslation } from "react-i18next";

const { FaCamera } = require("react-icons/fa");
const { default: PlayerPhoto } = require("./PhotoPlayer");
const { useState } = require("react");
const { default: PlayerLevel } = require("./PlayerLevel");

const EditPlayer = ({ value, onEdit, onCancel }) => {
  const [player, setPlayer] = useState(value);
  const { t } = useTranslation();
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPlayer({ ...player, photo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h3 className="text-xl font-semibold mb-4">{t('edit-player')}</h3>
        <div className="flex items-center justify-center h-32 flex-col">
          <PlayerPhoto photo={player.photo} size={80} />
          <div className="pt-4 pb-8">
            {" "}
            <label
              htmlFor="edit-photo-upload"
              className="cursor-pointer bg-gray-200 p-2 rounded hover:bg-gray-300 transition duration-300"
            >
              <FaCamera className="inline-block mr-2" /> {t('change-photo')}
            </label>
          </div>
          <input
            id="edit-photo-upload"
            type="file"
            accept="image/*"
            onChange={(e) => handlePhotoUpload(e)}
            className="hidden"
          />
        </div>
        <input
          type="text"
          value={player.name}
          maxLength="10"
          onChange={(e) => setPlayer({ ...player, name: e.target.value })}
          className="w-full p-2 border rounded mb-4"
        />
        <div className="flex items-center space-x-2 mb-4">
          <span>{t('level')}:</span>
          <div className="flex items-center text-2xl">
            <PlayerLevel
              level={player.level}
              onClick={(level) => {
                setPlayer({ ...player, level });
              }}
            />
          </div>
        </div>
        <div className="flex items-center space-x-2 mb-4"></div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onCancel}
            className="bg-gray-300 text-black p-2 rounded hover:bg-gray-400 transition duration-300"
          >
            {t('cancel')}
          </button>
          <button
            onClick={() => onEdit(player)}
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-300"
          >
            {t('update')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPlayer;
