import { useTranslation } from "react-i18next";

const { FaArrowDown, FaRandom, FaArrowUp } = require("react-icons/fa");

const DrawParameters = ({ onDraw, onUp, onDown, playersPerTeam }) => {

  const { t } = useTranslation();

  return (
    <div className="mb-8">
      <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white text-center">
        {t('team-draw')}
      </h2>
      <div className="flex flex-col items-center space-y-4 md:space-y-0 md:space-x-4 mb-4">
        <label className="text-white">{t('players-per-team')}:</label>
        <div className="flex items-center pb-10">
          <button
            onClick={onDown}
            className="bg-white p-2 rounded-l hover:bg-slate-50 transition duration-300 h-20 w-20 text-lg  flex justify-center items-center"
          >
            <FaArrowDown size={30} />
          </button>
          <input
            type="text"
            disabled={true}
            value={playersPerTeam}
            className="w-16 p-2 text-center border-t border-b h-20 bg-slate-200"
          />
          <button
            onClick={onUp}
            className="bg-white p-2 rounded-r hover:bg-slate-50 transition duration-300 h-20 w-20 flex justify-center items-center"
          >
            <FaArrowUp size={30} />
          </button>
        </div>

        <button
          onClick={onDraw}
          className="bg-white text-black p-2 rounded w-full h-20 text-lg "
        >
          <FaRandom className="inline-block mr-2" size={30} /> {t('draw-teams')}
        </button>
      </div>
    </div>
  );
};

export default DrawParameters;
