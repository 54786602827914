
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


const useBalanceTeams = () => {

  const { t } = useTranslation();

  const balanceTeams = (players, playersPerTeam) => {

    if (playersPerTeam === 0) {
      toast.warning(t('select-the-number-of-players-per-team'));

      return;
    }
    console.log('deverria retornar', playersPerTeam)
    const sortedPlayers = [...players].sort(
      (a, b) => a.arrivalOrder - b.arrivalOrder
    );

    // Inicializa o array de times
    const numTeams =
      Math.floor(sortedPlayers.length / playersPerTeam) +
      (sortedPlayers.length % playersPerTeam > 0 ? 1 : 0);
    const newTeams = Array.from({ length: numTeams }, () => []);

    // Função para embaralhar um array
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    // Função para equilibrar equipes com base na média de nível
    const balanceTeams = (playersPair) => {
      const team1 = [];
      const team2 = [];

      const sortedPLayer = playersPair.sort((a, b) => b.level - a.level);

      sortedPLayer.forEach((player) => {
        if (team1.length < playersPerTeam && team1 <= team2) {
          team1.push(player);
        } else {
          team2.push(player);
        }
      });

      return [team1, team2];
    };

    const numPairs = Math.floor(numTeams / 2);

    for (let i = 0; i < numPairs; i++) {
      const playersPair = sortedPlayers.slice(
        i * playersPerTeam * 2,
        (i + 1) * playersPerTeam * 2
      );

      shuffleArray(playersPair);

      const [team1, team2] = balanceTeams(playersPair);

      newTeams[i * 2] = team1;
      newTeams[i * 2 + 1] = team2;
    }

    if (numTeams % 2 !== 0) {
      const remainingPlayers = sortedPlayers.slice(
        numPairs * playersPerTeam * 2
      );
      remainingPlayers.forEach((player) => {
        const teamsSortedByLevelAndSize = newTeams
          .map((team, teamIndex) => ({
            team,
            index: teamIndex,
            totalLevel: team.reduce((sum, p) => sum + p.level, 0),
            size: team.length,
          }))
          .filter((t) => t.size < playersPerTeam) // Filtra apenas as equipes que ainda não estão cheias
          .sort(
            (a, b) =>
              a.totalLevel - b.totalLevel ||
              a.size - b.size ||
              a.index - b.index
          );

        if (teamsSortedByLevelAndSize.length > 0) {
          teamsSortedByLevelAndSize[0].team.push(player);
        }
      });
    }

    toast.success(t('teams-successfully-drawn'));

    return newTeams;

  }


  return { balanceTeams }

};

export default useBalanceTeams;
